<template>
  <div
    v-if="showICPNumber"
    class="background-section"
  >
    <div class="address-link">
      <router-link :to="{name:'Address'}">联系我们</router-link>
    </div>
    <div class="name">{{ config.name }}</div>
    <div class="copy-right">
      <span
        class="link"
        @click="handleLink"
      >
        {{ config.beian }}
      </span>
      <span>Copyright © {{ config.name }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted } from 'vue'
  import { DOMAIN_CONFIG } from '../config/domain.config'
  import type { IDomainConfig } from '../config/domain.config'

  const showICPNumber = ref(false)
  const config = ref<IDomainConfig>(DOMAIN_CONFIG.default)

  const handleLink = () => {
    window.location.href = 'https://beian.miit.gov.cn'
  }

  onMounted(() => {
    const hostname = window.location.hostname
    showICPNumber.value = !!(DOMAIN_CONFIG[hostname])
    config.value = DOMAIN_CONFIG[hostname] || DOMAIN_CONFIG.default
    document.title = DOMAIN_CONFIG[hostname].name
  })

</script>

<style lang="stylus">
  body
    margin 0px
  .link
    cursor pointer
  .name
    position absolute
    top 50%
    left 50%
    text-align center
    transform translate(-50%,-50%)
    font-size 48px
    font-weight 600
    color white
  .background-section
    width 100vw
    height 100vh
    display flex
    flex-direction column
    justify-content space-between
    align-items flex-end
    background-image url('https://ci.xiaohongshu.com/cb1d76b4-a166-449c-a9b7-0f5806979546')
    background-size cover
    background-repeat no-repeat
    .address-link
      width 30%
      display flex
      justify-content center
      align-items flex-end
      height 60px
      &>a
        text-decoration none
        color white
        font-size 18px
        letter-spacing 1px
        font-weight 500
    .copy-right
      margin-right 100px
      margin-bottom 30px
      display flex
      flex-direction column
      span
        font-weight 500
        font-size 18px
        line-height 158%
        color black
  //       letter-spacing 0.5px
</style>
