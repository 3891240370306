<template>
  <div class="background-section">
    <div class="title">联系我们</div>
    <div class="address">地址：武汉东湖新技术开发区光谷三路777号A办公楼402-196号</div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="stylus" scoped>
    body
        margin 0px
    .title
        width 100%
        height 100px
        display flex
        justify-content center
        align-items flex-end
        font-size 36px
        font-weight 500
        letter-spacing 0.5px
        color white
    .background-section
        width 100vw
        height 100vh
        display flex
        flex-direction column
        justify-content space-between
        align-items flex-end
        background-image url('https://ci.xiaohongshu.com/8e5fc61a-b0b0-435d-8000-ff42a2533e6b')
        background-size cover
        background-repeat no-repeat
        .address
            color white
            width 100%
            height 100px
            line-height 100px
            text-align center
            font-size 24px
            font-weight 500
            letter-spacing 0.5px
</style>
